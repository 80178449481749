var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "py-4" },
    [
      _c("guide", {
        ref: "guideRef",
        attrs: {
          btnTitle: "添加",
          propsProductionId: _vm.productionId,
          propsPlatformId: _vm.getPlatformId,
          departmentIds: _vm.parent.departmentIds,
          isDefaultTypeId: true,
          userInfo: _vm.userInfo,
          isAdmin: _vm.isAdmin,
          isDetail: true,
          propsUrl: _vm.url,
          isUseBodyLayoutStyleProps: true,
          isProduct: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }