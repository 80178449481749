<template>
  <div class="py-4">
    <!-- 指南 -->
    <guide
      ref="guideRef"
      btnTitle="添加"
      :propsProductionId="productionId"
      :propsPlatformId="getPlatformId"
      :departmentIds='parent.departmentIds'
      :isDefaultTypeId="true"
      :userInfo="userInfo"
      :isAdmin="isAdmin"
      :isDetail="true"
      :propsUrl="url"
      :isUseBodyLayoutStyleProps="true"
      :isProduct='true'
    ></guide>
  </div>
</template>

<script>
import Guide from './guide/guide-base'
export default {
  components: {
    Guide
  },
  props: {
    parent: {
      type: Object,
      default: () => {}
    },
    tab: {
      type: String,
      default: '1'
    },
    userInfo: {
      type: Object,
      default: () => ({})
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    tab(n) {
      if (n == 2) {
        if (this.$route.params.id == 'new') {
          this.productionId = this.parent.id
          this.$refs.guideRef.id = this.productionId
          this.$refs.guideRef.productionId = this.productionId
          this.$refs.guideRef.loadData({}, this.productionId)
        }
      }
    }
  },
  data() {
    return {
      productionId: this.parent.id || this.$route.params.id + '',
      url: {
        list: '/production/get_guide',
        delete: '/production/delete_guide',
        deleteBatch: '/production/delete_guide_batch',
        edit: '/production/edit_guide',
        add: '/production/add_guide'
      }
    }
  },
  methods: {},
  computed: {
    getPlatformId: {
      get: function() {
        return this.parent.platformId + ''
      }
    }
  },
  created() {}
}
</script>

<style lang="less"></style>
